<template>
  <div>
    <heads :msg="title_msg"></heads>
    <div class="code_login">
      <div class="phone">
        <div class="diqu">
          <input v-model="guoji" type="text">
          <!-- <img src="../../assets/down_t.png" alt="" /> -->
        </div>
        <input
          v-model="phone"
          class="phone_inp"
          type="text"
          placeholder="请输入手机号"
        >
      </div>
      <div class="code_box">
        <div class="code_m">
          <input v-model="ucode" type="text" placeholder="请输入验证码" @input="myInput()">
        </div>
        <div v-show="isTime" class="get_code" @click="getcode()">获取验证码</div>
        <div v-show="!isTime" class="get_code1">{{ times }}s</div>
      </div>
    </div>
    <div class="btn">
      <button :class="{nexts:mydisabled,nexts_red:!mydisabled}" :disabled="mydisabled" @click="sign_in()">下一步</button>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { getphoneCode, signIn } from '@/api/login.js'
import { Toast } from 'vant'
export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '填写手机号码',
      guoji: '+86',
      phone: '', // 手机号
      ucode: '', // 验证码
      isTime: true,
      times: 60,
      mydisabled: true,
      randstr: '',
      ticket: ''
    }
  },
  methods: {
    sign_in() {
      var params = {
        account_name: this.phone,
        code: this.ucode,
        type: 1
      }
      signIn(params).then((res) => {
        console.log(res)
        // if (this.$route.query.popup_id && res.errNo !== 0) {
        //   window.location.href = `http://openwww.zhulong.com/interview/#/hrresume?${this.$route.query.popup_id}`
        //   return false
        // }
        if (res.errNo !== 0) {
          Toast(res.msg)
        } else {
          Toast('注册成功')
          this.$router.go(-1)
          // this.next_create()
        }
      })
    },
    next_create() {
      console.log(111)
      this.$router.push({
        path: '/login/CreateAccount',
        query: {

        }
      })
    },
    myInput() {
      console.log(this.ucode.length)
      if (this.ucode.length > 0) {
        this.mydisabled = false
      } else if (this.ucode.length === 0) {
        this.mydisabled = true
      }
    },
    getcode() {
      const appid = '2090316930' // 腾讯云控制台中对应这个项目的 appid
      // 生成一个滑块验证码对象
      const that = this
      const captcha = new TencentCaptcha(appid, function(res) {
        // eslint-disable-line
        // 用户滑动结束或者关闭弹窗，腾讯返回的内容
        console.log(res)
        if (res.ret === 0) {
          console.log(111)
          console.log(111)
          // 成功，传递数据给后台进行验证
          that.randstr = res.randstr
          that.ticket = res.ticket
          // that.gettime()
          that.sendYzm()
        } else {
          // 提示用户完成验证
        }
      })
      // 滑块显示
      captcha.show()
    },
    sendYzm() {
      var params = {
        app_id: 1,
        mobile: this.phone,
        ticket: this.ticket,
        randstr: this.randstr
      }
      getphoneCode(params).then((res) => {
        if (res.errNo !== 0) {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.code_login {
  width: 92%;
  margin: 0 4%;
  margin-top: 55px;
  height: 177px;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #dcdcdc;
  .phone {
    height: 88px;
    font-size: 32px;
    line-height: 88px;
    border-bottom: solid 1px #dcdcdc;
    width: 100%;
    .diqu {
      width: 150px;
      float: left;
      border-right: 3px solid #dcdcdc;
      overflow: hidden;
      margin-top: 25px;
      height: 40px;
      input {
        float: left;
        width: 50%;
        font-size: 30px;
        margin-left: 20px;
        height: 40px;
        line-height: 4%;
      }
      img {
        float: left;
        width: 26px;
        margin-top: 15px;
      }
    }
    .phone_inp {
      width: 50%;
      height: 50px;
      line-height: 50px;
      margin-top: 20px;
      margin-left: 5%;
    }
  }
  .code_box {
    .code_m {
      width: 70%;
      float: left;
      height: 70px;
      margin-top: 10px;
      border-right: 2px solid #dcdcdc;
      input {
        line-height: 70px;
        margin-left: 20px;
        font-size: 32px;
      }
    }
    .get_code {
      float: left;
      color: #ee2e2e;
      font-size: 32px;
      width: 28%;
      text-align: center;
      line-height: 88px;
    }
    .get_code1 {
      float: left;
      color: #333333;
      font-size: 32px;
      width: 28%;
      text-align: center;
      line-height: 88px;
    }
  }
}
.nexts {
  display: block;
  width: 92%;
  height: 100px;
  margin: 50px 0px 0 30px;
  font-size: 32px;
  letter-spacing: 3px;
  color: #fff;
  line-height: 44px;
  text-align: center;
  background: #f57f7f;
  border-radius: 5px;
}
.nexts_red {
  display: block;
  width: 92%;
  height: 100px;
  margin: 50px 0px 0 30px;
  font-size: 32px;
  letter-spacing: 3px;
  color: #fff;
  line-height: 44px;
  text-align: center;
  background: #ee2e2e;
  border-radius: 5px;
}
</style>
